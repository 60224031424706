import {useStripe, Elements, CardElement, useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import React from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

const stripePromise = loadStripe('pk_test_51QC2kyKVZbTMKe00UqFUT3IhW1XIyka9lkeybSt3zvC26qt83FxY9zC5z8zpRxL166nMjEBlWmeBlfRdu2hMwN0y00pZaEV1kz');

function CheckoutForm({ user, app }) {
  const stripe = useStripe();
  const elements = useElements();
  const functions = getFunctions(app);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

	  const {error, paymentMethod } = await stripe.createPaymentMethod({
		  type: 'card',
		  card: elements.getElement(CardElement),
		  });
	  
	  if (error) {
		  console.log('[error]', error);
	  } else {
		  try {
			  const createSubscription = httpsCallable(functions, 'createSubscription');
        const result = await createSubscription({ paymentMethodId: paymentMethod.id });
        console.log('Subscription created:', result.data);
        // Handle successful subscription (e.g., show a success message, redirect user)
      } catch (error) {
        console.error('Error creating subscription:', error);
        // Handle error (e.g., show error message to user)
  }}};

	  

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit" disabled={!stripe}>
        Subscribe
      </button>
    </form>
  );
};

function SubscribePage({ user }) {
  if (!user) {
    return <div>Please log in to subscribe.</div>;
  }

  return (
    <div>
      <h1>Subscribe to Premium Content</h1>
      <Elements stripe={stripePromise}>
        <CheckoutForm user={user} />
      </Elements>
    </div>
  );
}

export default SubscribePage;
