import React from 'react';
import {Link} from 'react-router-dom';

function HomePage({user}){
	return (
		<div>

			<h1>Welcome to The Big Changes</h1>
<p>The world is changing in really big ways.</p>

<p>The big changes include AI, aging societies and quantum computing.</p>

{/*
<p>The big changes include AI, aging societies in developed countries, the wealth and power of different countries, robots, biotechnology, rising individual wealth levels, wealth distribution, cultural norms, global institutions, climate policies, and quantum computing.</p>
*/}


<p>But we are making wrong decisions today because we don’t have a good idea of what the world will look like in 10 and 20 years from now.</p>

<p>It takes weeks of research to get a good idea about what will happen for a specific change, and decision makers don’t have time for that.</p>

<p>The big changes does the research and modeling work to predict what will happen over the next 25 years.</p>

<p>By using this analysis, decision makers can make better choices for companies and governments - making for a better future.</p>

<p>Research reports:</p>

<p><a href="/how-ai-will-change-employment">How AI Will Change Employment</a></p>
		{/*
		{user ? (
			<p>Welcome, {user.email}! {!user.isPaidSubscriber && <Link to='/subscribe'>Subscribe</Link>}</p>) : (
				<p><Link to="login">Log in</Link> or <Link to="/subscribe">Subscribe</Link></p>
			)}
			*/}
		</div>

	);
}

export default HomePage;
